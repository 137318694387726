import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Text,
} from '@chakra-ui/react';

import { StrapiSectionDetail } from '@/customtypes/global';

interface FAQProps {
  sectionDetails: StrapiSectionDetail;
  faqs: Array<{
    question: string;
    answer: any;
  }>;
}

const FAQsContent = ({ sectionDetails, faqs }: FAQProps) => {
  return (
    <Box mt={{ base: '64px', lg: '80px' }} bg="backgroundPrimary" w="100%">
      <Center>
        <Accordion allowToggle w="100%" maxW="1176px" textAlign="left">
          <AccordionItem border="0px" bg="backgroundPrimary" textAlign="left">
            <h2>
              <AccordionButton
                p={{ base: '1.5rem 1rem', lg: '1.5rem 0' }}
                _hover={{ bg: 'backgroundPrimary' }}
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight="600"
                  fontSize={{ base: '14px', lg: '16px' }}
                  lineHeight={{ base: '20px', lg: '24px' }}
                  color="textPrimary"
                >
                  {sectionDetails.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb="80px" px={{ base: '1rem', lg: 0 }} pt="0">
              {faqs.map((item) => (
                <Box mt="24px" key={item.question}>
                  <Text
                    as={item.answer ? 'h2' : 'h1'}
                    fontWeight="600"
                    fontSize={{ base: '14px', lg: '16px' }}
                    lineHeight={{ base: '20px', lg: '24px' }}
                    color="textPrimary"
                  >
                    {item.question}
                  </Text>
                  <Box
                    mt="12px"
                    color="textSecondary"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                    fontWeight="500"
                    fontSize={{ base: '10px', lg: '12px' }}
                    lineHeight={{ base: '14px', lg: '18px' }}
                    sx={{
                      '& ol': {
                        pl: { base: 2, lg: 4 },
                      },
                      '& ul': {
                        pl: { base: 2, lg: 4 },
                      },
                    }}
                    className="faqAnswer"
                  />
                </Box>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Center>
    </Box>
  );
};

export default FAQsContent;
